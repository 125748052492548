import { ROLES } from "helpers";
import { useSelector } from "react-redux";

export default function TabsReport({ active, setActive }) {
  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";
  const { rol } = useSelector((state) => state.auth);

  return (
    <div className="flex">
      {[
        {
          text: "Grafica",
          index: 0,
          roles: [1, 9],
        },
        {
          text: "Ventas general",
          index: 1,
          roles: [1, 9],
        },
        {
          text: "Reporte estatus",
          index: 2,
          roles: [1, 9],
        },
        {
          text: "Reporte estatus asesor",
          index: 3,
          roles: [1, 9],
        },
        {
          text: "Seguimiento a ids",
          index: 4,
          roles: [1, 9, ROLES.MESA_1, ROLES.MESA_2],
        },
      ]
        .filter((item) => {
          return item.roles.includes(rol);
        })
        .map((item) => {
          return (
            <button
              className={`px-4 py-2  ${
                active === item.index ? getClassActive() : "bg-gray-200"
              }`}
              onClick={() => setActive(item.index)}
            >
              {item.text}
            </button>
          );
        })}
    </div>
  );
}
