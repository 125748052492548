import InputField from "components/fields/InputField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDataAuht } from "../../redux/slice/auth";
import Swal from "sweetalert2";
import ModalGeneral from "components/modal";
import api from "service";

export default function SignIn() {
  const [form, setForm] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [show, setShow] = useState(false);

  const { REACT_APP_URL_BASE, REACT_APP_AMBIENTE } = process.env;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userName, id } = useSelector((state) => state.auth);

  const handleAuth = async ({ email, password }) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ email, password, isApp: false }),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    setLoading(true);
    const endpoint = `${REACT_APP_URL_BASE}/auth`;
    const response = await fetch(endpoint, options)
      .then((res) => res.json())
      .catch((error) => {
        return null;
      });
    setLoading(false);

    if (typeof response === "string") {
      if (response === "Contacte a su administrador") {
        Swal.fire({
          title: "Error",
          text: "Contacte al administrador",
        });
      }
      return;
    }
    if (response?.jwt) {
      localStorage.setItem("auth", response.jwt);
      localStorage.setItem("rol", response.rolId);
      await dispatch(
        setDataAuht({
          token: response.jwt,
          routes: response.permission,
          rol: response.rolId,
          userName: response.userName,
          id: response.id,
        })
      );
      return navigate("/admin/default");
    }else {
      Swal.fire({
        title: "Error",
        text: "Contacte al administrador",
      });
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4
          className={`mb-2.5 text-4xl font-bold dark:text-white ${
            REACT_APP_AMBIENTE == 0 ? "text-red-500" : "text-navy-700"
          }`}
        >
          {REACT_APP_AMBIENTE == 0 ? "AMBIENTE DE PRUEBAS" : " Iniciar sesión"}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Ingresa usuario y contraseña
        </p>

        <InputField
          variant="auth"
          extra="mb-3"
          label="Correo*"
          placeholder=""
          id="email"
          type="text"
          value={form.email}
          onChange={(event) => {
            setForm((old) => {
              return { ...old, email: event.target.value };
            });
          }}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Contraseña"
          placeholder=""
          id="password"
          type="password"
          value={form.password}
          onChange={(event) => {
            setForm((old) => {
              return { ...old, password: event.target.value };
            });
          }}
        />
        <div className="mb-4 flex items-center justify-between px-2 text-red-400">
          {error}
        </div>
        <button
          className="linear hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
          onClick={() => {
            handleAuth({
              email: form.email,
              password: form.password,
            });
          }}
          disabled={loading}
        >
          {loading ? "Verificando" : "Entrar"}
        </button>
        {show && (
          <ModalGeneral
            title="Aceptar términos y condiciones"
            close={() => {
              setShow(false);
            }}
          >
            <div className="h-[650px] w-[700px] overflow-y-scroll p-10">
              <p>
                Yo, {userName}, en calidad de usuario de la aplicación “Mi
                Dinerito Digital”, me comprometo a cumplir con las siguientes
                condiciones:
              </p>
              <br />
              <h4 className="font-bold">Confidencialidad de Acceso.</h4>
              <ol>
                <li>
                  Mantendré en absoluta confidencialidad mi usuario y contraseña
                  asignados para acceder a “Mi Dinerito Digital”. Asumo la
                  responsabilidad total de la guarda, custodia y uso adecuado de
                  estas credenciales, las cuales son personales e
                  intransferibles. Comprendo que el mal uso de esta información
                  podría tener consecuencias operativas, financieras, legales y
                  disciplinarias. Acepto que cualquier actividad realizada en la
                  aplicación con mis credenciales es de mi exclusiva
                  responsabilidad.
                </li>
              </ol>
              <br />

              <h4 className="font-bold">
                Uso Exclusivo para Tareas Autorizadas.
              </h4>
              <ol>
                <li>
                  Utilizaré “Mi Dinerito Digital” exclusivamente para la gestión
                  de revisión y autorización créditos con clientes de
                  FINPROSPERA S.A.P.I. DE C.V. SOFOM E.N.R. de acuerdo a mi
                  perfil de atribuciones; No compartiré información ni realizaré
                  transferencias de datos a competidores ni terceros, y me
                  abstendré de realizar cualquier acción que infrinja las
                  políticas de seguridad de la información y confidencialidad de
                  datos de los clientes.
                </li>
              </ol>
              <br />
              <h4 className="font-bold">Atributos:</h4>
              <ul>
                <li>
                  Ver todos los folios de crédito gestionados por el área
                  Comercial.
                </li>
                <li>Autorización de folios para enviar como crédito a Zell.</li>
                <li>Autorización de crédito sin acuse CEP.</li>
                <li>
                  Visualización de documentos/información de todos los folios
                  asignados.
                </li>
                <li>Descarga de expedientes de crédito.</li>
                <li>Cancelación de créditos.</li>
                <li>Simulador del crédito.</li>
                <li>Asignación de Asesores.</li>
                <li>Envió de correo de autorización de crédito al cliente.</li>
                <li>Enviar folio a devolución/Por atender.</li>
                <li>Ver bitácora de créditos.</li>
                <li>Recotización de créditos.</li>
                <li>Ver acuse CEP.</li>
              </ul>
              <br />

              <h4 className="font-bold">
                Adherencia a los Lineamientos del Manual de Usuario.
              </h4>
              <ol>
                <li>
                  Seguiré los lineamientos indicados en el Manual de Usuario
                  proporcionado por el área de Sistemas y Tecnología para el uso
                  adecuado de la aplicación. En caso de dudas o inquietudes, me
                  comprometo a contactar con esta área para obtener orientación.
                </li>
              </ol>
              <br />

              <h4 className="font-bold">Consecuencias por Incumplimiento.</h4>
              <ol>
                <li>
                  Reconozco que el incumplimiento de las políticas y normas en
                  el uso de “Mi Dinerito Digital” puede comprometer la
                  integridad de las operaciones de FINPROSPERA S.A.P.I. DE C.V.
                  SOFOM E.N.R. y afectar la relación con los clientes. Acepto
                  las consecuencias legales y disciplinarias establecidas en el
                  Reglamento Interno de Sanciones en caso de un uso indebido de
                  la aplicación.
                </li>
              </ol>

              <br />

              <h4 className="font-bold">
                Devolución de Credenciales en Caso de Desvinculación.
              </h4>
              <ol>
                <li>
                  En caso de desvinculación profesional de FINPROSPERA S.A.P.I.
                  DE C.V. SOFOM E.N.R., me comprometo a entregar mis
                  credenciales de acceso al área de Sistemas y Tecnología,
                  notificando a Recursos Humanos.
                </li>
              </ol>
              <br />
              <p>
                En virtud de lo anterior, yo {userName}, , mediante mi firma a
                continuación, ratifico mi compromiso de utilizar la aplicación
                “Mi Dinerito Digital” de manera responsable y en cumplimiento de
                las normas establecidas por FINPROSPERA S.A.P.I. DE C.V. SOFOM
                E.N.R.
              </p>
              <button
                className="linear hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
                onClick={() => {
                  api.put(`users/${id}/acuerdos`, {}).then((res) => {
                    setShow(false);
                    return navigate("/admin/default");
                  });
                }}
                disabled={loading}
              >
                Aceptar acuerdos
              </button>
            </div>
          </ModalGeneral>
        )}
      </div>
    </div>
  );
}
