import { useState } from "react";
import TabsReport from "./components/tabs";
import SalesGeneral from "./reports/sales-generales";
import StatusSolicitudes from "./reports/status-solicitudes";
import StatusAsesor from "./reports/status-asesor";
import Grafica from "./reports/grafica";
import SeguimientoIds from "./reports/seguimiento-ids";

const Ventas = ({ module }) => {
  const [active, setActive] = useState(0);

  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <TabsReport active={active} setActive={setActive} />
      <div className="p-4">
        {active === 1 && <SalesGeneral />}
        {active === 2 && <StatusSolicitudes />}
        {active === 3 && <StatusAsesor />}
        {active === 0 && <Grafica />}
        {active === 4 && <SeguimientoIds />}
      </div>
    </div>
  );
};

export default Ventas;
