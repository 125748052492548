import { format, parseISO } from "date-fns";

const PenultimoReciboNomina = (type, documentos) => {
  if (!documentos) return null;
  return documentos.find((doc) => doc.type === type);
};

export function parseJwt(token) {
  if (!token) return null;
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Token JWT inválido");
  }
  const payload = parts[1];
  const decodedPayload = atob(payload.replace(/-/g, "+").replace(/_/g, "/"));
  return JSON.parse(decodedPayload);
}

export function NumberMxn(value) {
  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function formatDate(value) {
  return format(parseISO(value), "yyyy-MM-dd HH:mm");
}
export default PenultimoReciboNomina;

export const ROLES = {
  MESA_1: 4,
  MESA_2: 6,
  MESA_3: 7,
  MESA_4: 8,
  ADMIN: 1,
  SUPER_ADMIN: 9,
};
