import toast from "react-hot-toast";
import { Upload, Loader2 } from "lucide-react";
import { useRef, useState } from "react";
import api from "service";

const FileUpload = ({ handleClose, type }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave") {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = [...e.dataTransfer.files];
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    const file = files[0];
    if (
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      toast.error("Por favor sube un archivo Excel válido");
      return;
    }
    const headers = { "Content-Type": "multipart/form-data" };
    setIsLoading(true);
    const form = new FormData();
    let endpoint = "system/upload-black-list";
    if (type === "periodos") {
      form.append("file", file);
      endpoint = "system/upload-periodos";
    } else if (type === "phone") {
      form.append("file", file);
      form.append("fileType", "phone-blocked");
      endpoint = "system/phone-blocked";
    } else {
      form.append("fileType", type);
      form.append("file", file);
    }
    api.post(endpoint, form, headers).finally(() => {
      toast.success("Archivo subido exitosamente");
      setIsLoading(false);
      handleClose && handleClose();
    });
  };

  return (
    <div
      className={`rounded-lg border-2 border-dashed p-8 text-center ${
        isDragging
          ? "border-custom-green bg-custom-green/10"
          : "border-gray-300"
      }`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept=".xlsx,.xls"
        onChange={(e) => {
          const files = e.target.files;
          if (files?.length) handleFiles([...files]);
        }}
      />
      {isLoading ? (
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="text-primary h-12 w-12 animate-spin" />
          <p className="text-lg">Procesando archivo...</p>
        </div>
      ) : (
        <>
          <Upload className="mx-auto mb-4 h-12 w-12 text-gray-400" />
          <p className="mb-2 text-lg">
            Arrastra y suelta tu archivo Excel aquí
          </p>
          <p className="mb-4 text-sm text-gray-500">o</p>
          <button
            onClick={() => fileInputRef.current?.click()}
            className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
          >
            Seleccionar archivo
          </button>
        </>
      )}
    </div>
  );
};

export default FileUpload;
