import { useMemo, useState } from "react";

export const SolvenciaCalculada = ({ data }) => {
  const [solvenciaNomipay, setSolvenciaNomipay] = useState(0);

  const total = useMemo(() => {
    if(!data) return 0
    return data.credits.reduce((a, b) => {
      return a + b.amountMenor;
    }, 0);
  }, [data]);

  return (
    <>
      <div className="mt-10 flex  flex-row gap-4">
        <strong>{total.toFixed(2)}</strong> <strong>+</strong>{" "}
        <input
          type="number"
          placeholder="solvencia nomipay"
          onChange={(event) => {
            const value = event.target.value;
            setSolvenciaNomipay(parseFloat(value));
          }}
        />
        = <strong>{(total + solvenciaNomipay).toFixed(2)}</strong>
      </div>
      <div className="mt-10 flex flex-col">
        {data?.credits.map((item) => {
          return (
            <div className="flex flex-col rounded-lg bg-gray-200 p-4">
              <div className="flex flex-row gap-x-6 ">
                <span>
                  Credito id: <strong>{item.creditId}</strong>
                </span>
                <span>
                  Monto Menor: <strong>{item.amountMenor}</strong>
                </span>
              </div>

              <div className="mt-4 flex flex-col gap-2">
                {item.lastPayment.map((payment) => {
                  return (
                    <div className="flex flex-row gap-4">
                      <span>Fecha: {payment.movementDate}</span>
                      <span>Estatus: {payment.opStatus}</span>
                      <span>Monto: {payment.totalPaid}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
