import Filter from "components/filter";
import Grid from "components/grid";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useState } from "react";
import api from "service";

export default function SeguimientoIds() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const handleFilter = () => {
    setLoading(true);
    return api
      .get(`/report/seguimiento-ids?start=${startDate}&end=${endDate}`)
      .then((res) => {
        if (res){
          setData(res)
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="p-4 duration-300">
      <Filter
        handleFilter={handleFilter}
        exportColumns={[
          { headerName: "Folio", field: "id" },
          { headerName: "Numero de empleado", field: "numero_empleado" },
          { headerName: "Nombre", field: "nombre" },
          { headerName: "Correo", field: "email" },
          { headerName: "Teléfono", field: "phone" },
          { headerName: "Curp", field: "curp" },
          { headerName: "Supervisor", field: "supervisor" },
          { headerName: "Vendedor", field: "user" },
          { headerName: "Comienzo registro", field: "createdAt" },
          { headerName: "Final registro", field: "updatedAt" },
          { headerName: "Visible", field: "visible" },
        ]}
        dataExport={data}
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={[
            { name: "Folio", field: "id" },
            { name: "Numero de empleado", field: "numero_empleado" },
            { name: "Nombre", field: "nombre" },
            { name: "Correo", field: "email" },
            { name: "Teléfono", field: "phone" },
            { name: "Curp", field: "curp" },
            { name: "Supervisor", field: "supervisor" },
            { name: "Vendedor", field: "user" },
            { name: "Comienzo registro", field: "createdAt" },
            { name: "Final registro", field: "updatedAt" },
            { name: "Visible", field: "visible" },
          ]}
          data={data}
        />
      )}
    </div>
  );
}
