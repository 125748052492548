import axios from "axios";
import { store } from "../redux/store";
import { logout } from "../redux/slice/auth";
import Swal from "sweetalert2";

const endpoint = process.env.REACT_APP_URL_BASE;

const createInstance = () => {
  const api = axios.create({
    baseURL: endpoint,
    timeout: 122000,
  });

  api.interceptors.request.use(
    (config) => {
      const accessToken = store?.getState().auth.token;
      if (accessToken && !/\/auth(\/|$)/.test(config.url)) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      const moduleName = store?.getState().auth.moduleName;
      if (moduleName) {
        config.headers["module"] = `${moduleName}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  let isShowingSessionExpired = false;

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (!isShowingSessionExpired) {
          isShowingSessionExpired = true;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Su sesión ha expirado, ingrese de nuevo.",
          }).then((result) => {
            isShowingSessionExpired = false;
            if (result.isConfirmed) {
              store.dispatch(logout());
            }
          });
        }
      }
    }
  );

  const get = async (url) => {
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error(error)
      return null;
    }
  };

  const post = async (url, data, customHeaders = {}) => {
    try {
      Object.keys(customHeaders).forEach((key) => {
        api.defaults.headers.common[key] = customHeaders[key];
      });
      const response = await api.post(url, data);
      Object.keys(customHeaders).forEach((key) => {
        delete api.defaults.headers.common[key];
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const put = async (url, data) => {
    try {
      const response = await api.put(url, data);

      return response.data;
    } catch (error) {
      return null;
    }
  };

  return {
    get,
    post,
    put,
  };
};

const api = createInstance();

export default api;
