import React from "react";

const MetricCard = ({ title, value, color, delay = 0 }) => {
  const colorVariants = {
    blue: "bg-dashboard-blue",
    green: "bg-dashboard-green",
    orange: "bg-dashboard-orange",
    purple: "bg-dashboard-purple",
  };

  const bgColor = colorVariants[color] || "bg-dashboard-blue";

  return (
    <div
      className={`${bgColor} flex flex-col rounded-lg p-6 text-white shadow-sm transition-shadow duration-300 hover:shadow-md`}
    >
      <span className="text-sm font-medium opacity-90">{title}</span>
      <span className="mt-2 text-3xl font-bold">{value}</span>
    </div>
  );
};

export default MetricCard;
