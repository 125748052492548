import { useEffect, useState } from "react";
import api from "service";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format, parseISO, subMonths } from "date-fns";
import MetricCard from "./MetricCard";
import { endOfMonth, startOfMonth } from "date-fns";
import Filter from "components/filter";
import { NumberMxn } from "helpers";

export default function Grafica({ numberMonts }) {
  const [data, setData] = useState();
  const [sales, setSales] = useState();
  const [summary, setSummary] = useState([]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );

  useEffect(() => {
    if (numberMonts) {
      setStartDate(
        format(startOfMonth(subMonths(new Date(), numberMonts)), "yyyy-MM-dd")
      );
      handleFilter();
    }
  }, [numberMonts]);

  const handleFilter = () => {
    api
      .get(`report/report-chart?start=${startDate}&end=${endDate}`)
      .then((res) => {
        const { time, sales } = res;

        setSales(sales);

        const formattedData = time.map((item) => ({
          ...item,
          fecha: format(parseISO(item.fecha), "MMM yyyy"),
        }));
        setData(formattedData);

        const nuevos = time.reduce((sum, item) => sum + item.nuevos, 0);
        const adicionales = time.reduce(
          (sum, item) => sum + item.adicionales,
          0
        );
        const refinanciamientos = time.reduce(
          (sum, item) => sum + item.refinanciamientos,
          0
        );

        setSummary([
          {
            id: 1,
            title: "Nuevos",
            value: nuevos,
            color: "blue",
          },
          {
            id: 1,
            title: "Adicionales",
            value: adicionales,
            color: "green",
          },
          {
            id: 1,
            title: "Refinanciamientos",
            value: refinanciamientos,
            color: "orange",
          },
        ]);
      });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="rounded-md border border-gray-200 bg-white p-4 shadow-lg">
          <p className="font-semibold text-gray-800">{label}</p>
          {payload.map((entry, index) => (
            <p
              key={`item-${index}`}
              style={{ color: entry.color }}
              className="mt-2 flex items-center"
            >
              <span
                className="mr-2 h-3 w-3 rounded-full"
                style={{ backgroundColor: entry.color }}
              ></span>
              <span className="capitalize">{entry.name}:</span>
              <span className="ml-1 font-semibold">{entry.value}</span>
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Filter
        handleFilter={handleFilter}
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {summary.map((metric, index) => {
          return (
            <MetricCard
              key={metric.id}
              title={metric.title}
              value={metric.value}
              color={metric.color}
              delay={index}
            />
          );
        })}
      </div>
      <div className="h-screen rounded-lg bg-white p-6 shadow-sm">
        {data && (
          <ResponsiveContainer width="100%" height="50%">
            <AreaChart
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
            >
              <defs>
                <linearGradient id="colorNuevos" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8b5cf6" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#8b5cf6" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="colorAdicionales"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#4ade80" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#4ade80" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="colorRefinanciamientos"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#f97316" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#f97316" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                stroke="#f1f1f1"
                vertical={false}
              />
              <XAxis
                dataKey="fecha"
                tick={{ fill: "#6b7280", fontSize: 12 }}
                tickLine={false}
                axisLine={{ stroke: "#e5e7eb" }}
              />
              <YAxis
                tick={{ fill: "#6b7280", fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="bottom"
                height={36}
                iconType="circle"
                formatter={(value) => (
                  <span className="text-sm capitalize text-gray-600">
                    {value}
                  </span>
                )}
              />
              <Area
                type="monotone"
                dataKey="nuevos"
                name="nuevos"
                stroke="#8b5cf6"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorNuevos)"
                activeDot={{ r: 6, strokeWidth: 0 }}
              />
              <Area
                type="monotone"
                dataKey="adicionales"
                name="adicionales"
                stroke="#4ade80"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorAdicionales)"
                activeDot={{ r: 6, strokeWidth: 0 }}
              />
              <Area
                type="monotone"
                dataKey="refinanciamientos"
                name="refinanciamientos"
                stroke="#f97316"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorRefinanciamientos)"
                activeDot={{ r: 6, strokeWidth: 0 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}

        {sales && (
          <>
            <h3 className="text-xl font-bold">Ventas por Mes y Tipo</h3>
            <ResponsiveContainer width="100%" height="50%">
              <BarChart
                data={sales}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis
                  tickFormatter={(value) => `$${(value / 1000).toFixed(2)} mxn`}
                />

                <Tooltip
                  formatter={(value) => [NumberMxn(value), "Monto"]}
                  labelFormatter={(label) => `Mes: ${label}`}
                />
                <Legend />
                <Bar dataKey="new" name="Nuevo" fill="#0EA5E9" />
                <Bar dataKey="refis" name="Refis" fill="#F97316" />
                <Bar dataKey="additional" name="Adicional" fill="#8B5CF6" />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  );
}
