import { useEffect, useMemo, useState } from "react";
import { X } from "lucide-react";
import FileUpload from "./file-upload";
import ConfigCard from "./config-card";
import api from "service";
import Grid from "components/grid";
import { useSelector } from "react-redux";

const Modal = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="rounded-full p-1 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const Datas = () => {
  const [activeModal, setActiveModal] = useState();
  const [active, setActive] = useState(1);

  const [data, setData] = useState([]);
  const [ip, setIp] = useState("");
  const { rol } = useSelector((state) => state.auth);
  const modalType = useMemo(() => activeModal?.split("-")[1], [activeModal]);

  const LIST_CONFIG = {
    periods: {
      endpoint: "system/periodos",
      title: "Lista Periodos",
      upload: "Subir Periodos",
    },
    blacklist: {
      endpoint: "system/black-list?type=blacklist",
      title: "Lista Negra",
      upload: "Subir Lista Negra",
    },
    ips: {
      endpoint: "system/ip",
      title: "Lista de IPs",
      upload: "Agregar IPs",
    },
    products: {
      endpoint: "system/black-list?type=producto",
      title: "Lista Negra Productos",
      upload: "Lista Negra Productos",
    },
    phone: {
      endpoint: "system/phone-blocked",
      title: "Lista Negra de números de teléfono",
      upload: "Lista Negra de números de teléfono",
    },
  };

  const handleViewList = async (listType) => {
    setActiveModal(`view-${listType}`);
    if (!LIST_CONFIG[listType]) return;

    try {
      const res = await api.get(LIST_CONFIG[listType].endpoint);
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpload = (uploadType) => {
    setActiveModal(`upload-${uploadType}`);
  };

  const getColumns = () => {
    const views = activeModal?.split("-")[1];

    if (views === "periods") {
      return [
        { name: "Inicio", field: "start" },
        { name: "Fin", field: "end" },
        { name: "Fecha pago", field: "payment" },
      ];
    }
    if (views === "blacklist") {
      return [
        { name: "Nombre", field: "name" },
        { name: "CURP", field: "curp" },
      ];
    }

    if (views === "phone") {
      return [{ name: "Ip", field: "valor" }];
    }

    if (views === "ips") {
      return [{ name: "Ip", field: "valor" }];
    }
    return [
      { name: "Nombre", field: "name" },
      { name: "CURP", field: "curp" },
      { name: "PRODUCTO", field: "product" },
    ];
  };

  const fetchPublicIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const responseData = await response.json();
      setIp(responseData.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  const saveIP = async () => {
    try {
      await api.post("system/ip", { ip });
      setIp("");
      setActiveModal(null);
    } catch (error) {
      console.error("Error saving IP:", error);
    }
  };
  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  return (
    <div className="min-h-screen px-2 py-10">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {[1, 9].includes(rol) && (
            <ConfigCard
              title="Periodos"
              onViewList={() => handleViewList("periods")}
              onUpload={() => handleUpload("periods")}
            />
          )}

          {[1, 9, 11].includes(rol) && (
            <ConfigCard
              title="Lista Negra"
              onViewList={() => handleViewList("blacklist")}
              onUpload={() => handleUpload("blacklist")}
            />
          )}

          {[1, 9, 11].includes(rol) && (
            <ConfigCard
              title="Lista Negra Productos"
              onViewList={() => handleViewList("products")}
              onUpload={() => handleUpload("products")}
            />
          )}

          {[1, 9].includes(rol) && (
            <ConfigCard
              title="Ips permitidas"
              onViewList={() => handleViewList("ips")}
              onUpload={() => handleUpload("ips")}
              btnNormal={true}
            />
          )}
          {[1, 9].includes(rol) && (
            <ConfigCard
              title="CONVENIOS"
              onViewList={() => handleViewList("convenios")}
              onUpload={() => handleUpload("convenios")}
              btnNormal={true}
            />
          )}
          {[1, 9].includes(rol) && (
            <ConfigCard
              title="Números bloqueados"
              onViewList={() => handleViewList("phone")}
              onUpload={() => handleUpload("phone")}
              btnNormal={true}
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={activeModal?.startsWith("view-")}
        onClose={() => setActiveModal(null)}
        title={LIST_CONFIG[modalType]?.title || "Lista"}
      >
        <div className="space-y-4">
          <p className="text-gray-600">{LIST_CONFIG[modalType]?.title}</p>
          {data && data.length > 0 ? (
            <Grid colums={getColumns()} data={data} />
          ) : (
            <div className="rounded-lg bg-gray-50 p-4 text-center text-gray-500">
              No hay elementos para mostrar
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={activeModal?.startsWith("upload-")}
        onClose={() => setActiveModal(null)}
        title={LIST_CONFIG[modalType]?.upload || "Subir Archivo"}
      >
        {["periods", "blacklist", "products", "phone"].includes(modalType) && (
          <FileUpload
            handleClose={() => setActiveModal(null)}
            type={modalType}
          />
        )}

        {modalType === "ips" && (
          <div className="flex flex-col items-center gap-4">
            {ip ? (
              <>
                <p className="mb-2 text-lg">La IP pública es {ip}</p>
                <button
                  onClick={saveIP}
                  className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                >
                  Guardar para dar acceso
                </button>
              </>
            ) : (
              <>
                <p className="mb-2 text-lg">
                  Conéctate a la red y obtén la IP pública
                </p>
                <button
                  onClick={fetchPublicIP}
                  className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                >
                  Obtener IP pública
                </button>
              </>
            )}
          </div>
        )}

        {modalType === "convenios" && (
          <div className="flex flex-col items-start  bg-gray-100 ">
            <div className="flex flex-row">
              {[
                {
                  text: "Convenio",
                  index: 1,
                },
                {
                  text: "Dependencia",
                  index: 2,
                },
              ].map((item) => {
                return (
                  <button
                    className={`px-4 py-2  ${
                      active === item.index ? getClassActive() : "bg-gray-200"
                    }`}
                    onClick={() => setActive(item.index)}
                  >
                    {item.text}
                  </button>
                );
              })}
            </div>
            {active === 1 && (
              <div className="flex flex-col gap-4 p-4">
                <label htmlFor="">Ingresa el nombre del convenio</label>
                <input
                  type="text"
                  className=" w-full px-2 py-2"
                  placeholder="Ingresa el nombre"
                />
                <button
                  onClick={saveIP}
                  className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                >
                  Guardar
                </button>
              </div>
            )}

            {active === 2 && (
              <div className="flex flex-col gap-4 p-4">
                <div className="flex flex-col">
                  <label htmlFor="">Ingresa el nombre de la dependencia</label>
                  <input
                    type="text"
                    className=" w-full px-2 py-2"
                    placeholder="Ingresa el nombre"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Ingresa el codigo de la dependencia</label>
                  <input
                    type="text"
                    className=" w-full px-2 py-2"
                    placeholder="Ingresa el nombre"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Selecciona el convenio</label>
                  <select>
                    <option value={""}>Selecciona un valor</option>
                  </select>
                </div>
                <button
                  onClick={saveIP}
                  className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                >
                  Guardar
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Datas;
