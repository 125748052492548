import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import Filter from "components/filter";
import ModalGeneral from "components/modal";
import FormUser from "../form-user";
import Swal from "sweetalert2";
import {
  User,
  Mail,
  Phone,
  Calendar,
  Eye,
  Edit,
  Trash2,
  PowerOff,
  LockKeyhole,
  Smartphone,
} from "lucide-react";
import TableUx from "../grid";

const roleColumnMapping = {
  1: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  10: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  3: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  default: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Documentos" },
    { name: "Contraseña" },
  ],
};

const AsesoresUsers = ({
  rolId,
  handleChangeStatus,
  token,
  handleChangePassword,
  handleFreeAoo,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectUser, setSelectUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    if (token) {
      getAll();
    }
  }, [token, rolId]);

  const colums = useMemo(() => {
    return roleColumnMapping[rolId] || roleColumnMapping.default;
  }, [rolId]);

  const getAll = () => {
    const query = rolId == 1 ? `idRole=1&idRole=11` : `idRole=${rolId}`;
    const endpoint = `${process.env.REACT_APP_URL_BASE}/users?${query}`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={`py-4`}>
      <Filter
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={getAll}
        showFilterText={true}
        setTextSearch={setTextSearch}
        textSearch={textSearch}
        handleAdd={
          [2, 1, 10].includes(rolId)
            ? () => {
                setShowModal(true);
              }
            : null
        }
      />
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <TableUx
          data={data}
          textFilter={textSearch}
          colums={colums}
          keysFilter={["name", "email"]}
          ComponentRow={({ item, index }) => {
            return (
              <tr
                key={index}
                className="transition-colors duration-200 hover:bg-gray-50"
              >
                <td className="px-4 py-4">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 shrink-0 text-blue-500" />
                      <span
                        className="max-w-[240px] truncate font-medium text-gray-900"
                        title={item.nombre}
                      >
                        {item.name} {item.lastName}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Phone className="h-4 w-4 shrink-0 text-green-500" />
                      <span className="text-sm text-gray-600">
                        {item.phone}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Mail className="h-4 w-4 shrink-0 text-purple-500" />
                      <span
                        className="max-w-[240px] truncate text-sm text-gray-600"
                        title={item.email}
                      >
                        {item.email}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 shrink-0 text-indigo-500" />
                      <span className="text-sm text-gray-600">
                        {format(parseISO(item.createdAt), "yyyy-MM-dd hh:mm")}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <span
                    className={
                      "inline-flex max-w-[150px] items-center truncate rounded-full px-2.5 py-1 text-xs font-medium"
                    }
                  >
                    {item.role.name}
                  </span>
                </td>
                <td className="px-4 py-4">
                  <div className="space-y-1.5">
                    <button
                      onClick={() => {
                        handleChangeStatus({
                          id: item.id,
                          status: !item.active,
                          isDelete: false,
                        }).then(() => {
                          getAll();
                        });
                      }}
                      className="inline-flex w-full items-center justify-center rounded-md border border-gray-200 bg-white px-2 py-1.5 text-xs font-medium text-gray-700 transition-all duration-200 hover:bg-gray-50 hover:shadow-sm"
                    >
                      <PowerOff className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />

                      {item.active ? "Desactivar" : "Activar"}
                    </button>
                    <button
                      onClick={() => {
                        handleChangeStatus({
                          id: item.id,
                          status: false,
                          isDelete: true,
                        }).then(() => {
                          getAll();
                        });
                      }}
                      className="inline-flex w-full items-center justify-center rounded-md border border-red-200 bg-white px-2 py-1.5 text-xs font-medium text-red-600 transition-all duration-200 hover:bg-red-50 hover:shadow-sm"
                    >
                      <Trash2 className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />
                      Eliminar
                    </button>
                    <button
                      onClick={() => {
                        setUser(item);
                        setShowModal(true);
                      }}
                      className="inline-flex w-full items-center justify-center rounded-md border border-blue-200 bg-white px-2 py-1.5 text-xs font-medium text-blue-600 transition-all duration-200 hover:bg-blue-50 hover:shadow-sm"
                    >
                      <Edit className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />
                      Editar
                    </button>
                    {[2, 10].includes(rolId) && (
                      <button
                        onClick={() => {
                          handleFreeAoo({ id: item.id });
                        }}
                        className="inline-flex w-full items-center justify-center rounded-md border border-blue-200 bg-white px-2 py-1.5 text-xs font-medium text-blue-600 transition-all duration-200 hover:bg-blue-50 hover:shadow-sm"
                      >
                        <Smartphone className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />
                        Liberar acceso
                      </button>
                    )}
                  </div>
                </td>
                {[2].includes(rolId) && (
                  <td className="px-4 py-4">
                    <button
                      onClick={() => {
                        setSelectUser(item);
                      }}
                      className="inline-flex w-full items-center justify-center rounded-md border border-indigo-200 bg-white px-2 py-1.5 text-xs font-medium text-indigo-600 transition-all duration-200 hover:bg-indigo-50 hover:shadow-sm"
                    >
                      <Eye className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />
                      Ver Docs
                    </button>
                  </td>
                )}

                <td className="px-4 py-4">
                  <button
                    onClick={() => {
                      handleChangePassword({ id: item.id });
                    }}
                    className="inline-flex w-full items-center justify-center rounded-md border border-green-200 bg-white px-2 py-1.5 text-xs font-medium text-green-600 transition-all duration-200 hover:bg-green-50 hover:shadow-sm"
                  >
                    <LockKeyhole className="mr-1.5 h-3.5 w-3.5 stroke-[2.5]" />
                    Restablecer
                  </button>
                </td>
              </tr>
            );
          }}
        />
      )}

      {selectUser && selectUser.id > 0 && (
        <ModalGeneral
          close={() => {
            setSelectUser({});
          }}
          title="Documentos de usuario"
        >
          <div className="h-[450px] w-[690px]  flex-1 overflow-y-scroll px-2">
            {[
              {
                text: "INE FRENTE",
                type: "ine_front",
              },
              {
                text: "INE REVERSO",
                type: "ine_reverso",
              },
              {
                text: "Firma",
                type: "firma",
              },
            ].map((item) => {
              const document = selectUser.documents.find(
                (doc) => doc.type === item.type
              );

              return (
                <div>
                  <h5 className="font-bold">{item.text}</h5>
                  <div className="flex flex-row">
                    <img
                      src={document?.urlFile}
                      className="h-[200px] w-[350px] rounded-[10px] object-contain"
                    />
                    <div className="flex flex-col items-start justify-start gap-2 p-2">
                      <label class="block">
                        <span class="sr-only">Buscar archivo</span>
                        <input
                          type="file"
                          class="dark:text-neutral-500 block w-full text-sm text-gray-500 file:me-4 file:rounded-lg file:border-0 file:bg-blue-600 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-white hover:file:bg-blue-700 file:disabled:pointer-events-none file:disabled:opacity-50 dark:file:bg-blue-500 dark:hover:file:bg-blue-400"
                          id={item.type}
                          onChange={(value) => {
                            setFile(value.target.files[0]);
                          }}
                          accept="image/png, image/jpeg"
                        />
                      </label>
                      <button
                        type="button"
                        class="border-transparent inline-flex items-center gap-x-2 rounded-lg border bg-teal-500 px-4 py-3 text-sm font-semibold text-white hover:bg-teal-600 disabled:pointer-events-none disabled:opacity-50"
                        onClick={() => {
                          const endpoint = `${process.env.REACT_APP_URL_BASE}/users/${selectUser.id}/documents`;
                          if (!file) {
                            return Swal.fire({
                              type: "error",
                              title: "Seleccione el archivo",
                            });
                          }

                          let form = new FormData();
                          form.append("File", file);
                          form.append("Type", item.type);
                          setLoading(true);
                          fetch(endpoint, {
                            method: "POST",
                            headers: {
                              Authorization: "Bearer " + token,
                            },
                            body: form,
                          })
                            .then((res) => res.json())
                            .finally(() => {
                              getAll();
                              setLoading(false);
                            });
                        }}
                      >
                        SUBIR
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ModalGeneral>
      )}

      {showModal && (
        <FormUser
          title={rolId === 1 ? "Alta usuario admin" : "Alta de usuario"}
          onClose={() => {
            setShowModal(false);
            setUser();
          }}
          handleSucces={() => {
            getAll();
            setUser();

            setShowModal(false);
          }}
          endpoint={process.env.REACT_APP_URL_BASE}
          token={token}
          role={rolId}
          defaultValues={user}
          roles={
            rolId === 1
              ? [
                  {
                    value: 1,
                    text: "Admin It",
                  },
                  {
                    value: 11,
                    text: "Cobranza",
                  },
                ]
              : null
          }
        />
      )}
    </div>
  );
};

export default AsesoresUsers;
